<template>
  <div>
    <grant-promo-form-modal :user-id="$route.params.id" @granted="getData" />
    <revoke-promo-form-modal :user-id="$route.params.id" @revoked="getData" />
    <user-data-backups-modal v-if="user.id" :internal-id="user.id" />
    <p>
      <b-button
        variant="outline-secondary"
        @click="$router.back()"
      >
        Назад
      </b-button>
    </p>
    <div v-if="loaded">
      <b-tabs content-class="mt-3">
        <b-tab title="Профиль" active>
          <b-table-simple>
            <b-tbody>
              <b-tr>
                <b-th>ID</b-th>
                <b-td>{{ user.id }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>RevenueCat ID</b-th>
                <b-td>{{ user.app_user_id }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>Приложение было установлено из</b-th>
                <b-td>
                  {{ getAppStore(user.app_source_store) }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>Язык приложения пользователя</b-th>
                <b-td>{{ user.app_language }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>Версия приложения</b-th>
                <b-td>{{ user.app_version||'-' }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>E-mail</b-th>
                <b-td>
                  {{ user.email ? user.email : "–" }}
                  <b-icon-check-circle v-if="user.email_confirmed" />
                  <b-button
                    v-b-modal.modal-email
                    size="sm"
                    variant="warning"
                    class="ml-2"
                  >
                    Сменить E-mail
                  </b-button>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>Телефон</b-th>
                <b-td>
                  {{ user.phone ? user.phone : "–" }}
                </b-td>
              </b-tr>
              <b-tr v-if="user.is_google">
                <b-th>Регистрация через Google</b-th>
                <b-td class="pl-1">
                  <b-icon-x class="unlink" scale="1.75" @click="resetSocial('google')" />
                </b-td>
              </b-tr>
              <b-tr v-if="user.is_apple">
                <b-th>Регистрация через Apple</b-th>
                <b-td class="pl-1">
                  <b-icon-x class="unlink" scale="1.75" @click="resetSocial('apple')" />
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-tab>
        <b-tab title="Игровые данные">
          <div>
            <b-button
              v-b-modal.user-data-backups-modal
              variant="outline-secondary"
              size="sm"
              class="mb-2"
              :disabled="!user.id"
            >
              История изменений
            </b-button>
          </div>
          <tree-view
            :data="user.data"
            :options="{ modifiable: true }"
            @change-data="onChangeData"
          />
          <b-button
            :disabled="updateDataLoading"
            variant="primary"
            class="mt-3"
            @click="onSubmit"
          >
            <b-spinner v-if="updateDataLoading" small />
            Сохранить
          </b-button>
        </b-tab>
        <b-tab title="Подписки">
          <b-table :items="user.subscriptions" :fields="fields_sub">
            <template #cell(expires_date)="data">
              {{ getDate(data.item.expires_date) }}
            </template>

            <template #cell(status)="data">
              <b-badge v-if="data.item.status === 'active'" variant="success">
                Активна
              </b-badge>
              <b-badge v-else variant="danger">
                Закончилась
              </b-badge>
            </template>
          </b-table>
        </b-tab>
        <b-tab title="Доступы">
          <b-button v-b-modal.promo-form-modal variant="outline-secondary">
            Добавить промо-доступ
          </b-button>
          <b-button v-b-modal.revoke-promo-form-modal variant="outline-secondary" class="ml-2">
            Отозвать промо-доступ
          </b-button>
          <b-table
            :items="user.entitlements"
            :fields="fields_entitlement"
            class="mt-3"
          >
            <template #cell(expires_date)="data">
              {{ getDate(data.item.expires_date) }}
            </template>
          </b-table>
        </b-tab>
        <b-tab title="Устройства">
          <b-button-group>
            <b-button
              :disabled="selected_devices.length < 1"
              variant="outline-danger"
              size="sm"
              @click="block()"
            >
              <b-icon-lock />
              Блокировать
            </b-button>
            <b-button
              :disabled="selected_devices.length < 1"
              variant="outline-success"
              size="sm"
              @click="unlock()"
            >
              <b-icon-unlock />
              Активировать
            </b-button>
            <b-button
              :disabled="selected_devices.length < 1"
              variant="outline-primary"
              size="sm"
              @click="showToggleConfirm"
            >
              <b-icon-check2-circle />
              Отметка
            </b-button>
            <b-button
              :disabled="selected_devices.length < 1"
              variant="danger"
              size="sm"
              @click="remove()"
            >
              <b-icon-x-octagon />
              Удалить
            </b-button>
          </b-button-group>
          <b-table :items="user.devices" :fields="fields_devices" class="mt-3">
            <template #cell(checkbox)="data">
              <b-checkbox v-model="selected_devices" :value="data.item" />
            </template>
            <template #cell(hash)="data">
              <div :class="{'test-device': $store.getters.getTestHashes.includes(data.item.hash)}">
                {{ data.item.hash }}
              </div>
            </template>
            <template #cell(blocked)="data">
              <b-badge v-if="data.value === true" variant="danger">
                Заблокировано
              </b-badge>
              <b-badge v-else variant="success">
                Активно
              </b-badge>
            </template>
            <template #cell(last_login_at)="data">
              {{ getDate(data.value) }}
            </template>
          </b-table>
        </b-tab>
      </b-tabs>
    </div>
    <div v-else class="text-center text-primary my-2">
      <b-spinner class="align-middle" />
      <strong class="ml-3">Загрузка данных...</strong>
    </div>

    <b-modal id="modal-email" title="Изменение E-mail" hide-footer>
      <b-form @submit.prevent="changeEmail">
        <b-form-group label="E-mail">
          <b-form-input v-model="newEmail" type="email" required />
        </b-form-group>
        <b-form-group>
          <b-alert v-model="error.error" dismissible variant="danger">
            {{ error.message }}
          </b-alert>
          <b-button type="submit" variant="primary">
            Установить
          </b-button>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import GrantPromoFormModal from '@/components/GrantPromoFormModal'
import RevokePromoFormModal from '@/components/RevokePromoFormModal'
import UserDataBackupsModal from '../components/UserDataBackupsModal'

export default {
  name: 'UserShow',
  components: {GrantPromoFormModal, RevokePromoFormModal, UserDataBackupsModal},
  data() {
    return {
      newEmail: '',
      loaded: false,
      updateDataLoading: false,
      selected_devices: [],
      user: [],
      fields_sub: [
        { key: 'type', label: 'Тип' },
        { key: 'expires_date', label: 'Дата окончания' },
        { key: 'status', label: 'Статус' },
      ],
      fields_entitlement: [
        { key: 'type', label: 'Тип' },
        { key: 'expires_date', label: 'Дата окончания' },
      ],
      fields_devices: [
        { key: 'checkbox', label: '' },
        { key: 'hash', label: 'Hash' },
        { key: 'blocked', label: 'Статус' },
        { key: 'last_login_at', label: 'Дата последнего входа' },
      ],
      error: {error: false, message: ''},
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getAppStore(store){
      switch(store){
        case 'app_store': return 'Apple App Store'
        case 'play_store': return 'Google Play Store'
        case 'mac_app_store': return 'Mac App Store'
        default: return store
      }
    },
    async getData() {
      try {
        const response = await this.axios.get(process.env.VUE_APP_BACKEND_URL + '/admin/api/users/show/' + this.$route.params.id, this.$store.getters.getAxiosConfig)
        this.processUserData(response.data)
        this.loaded = true
        this.selected_devices=[]
      } catch (err) {
        console.error('🐹', err)
        this.$root.$emit('global-error')
        this.isLoading = false
      }
    },
    processUserData(data) {
      this.user = data
      this.newEmail = this.user.email
      if (this.user.subscriptions)
        this.user.subscriptions.map((sub) => {
          if (dayjs().diff(dayjs(sub.expires_date)) > 0)
            sub.status = 'inactive'
          else sub.status = 'active'
          return sub
        })
    },
    getDate(date) {
      if (date) return dayjs(date).format('DD.MM.YYYY HH:mm:ss')
      else return '-'
    },
    resetSocial(name) {
      this.$bvModal.msgBoxConfirm(`Отвязать аккаунт от ${name.charAt(0).toUpperCase() + name.slice(1)}?`, {
        title: 'Подтверждение',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Да',
        cancelTitle: 'Нет',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
      .then(value => {
        if (value) {
          this.error = { error: false, message: '' }
          this.axios
          .post(
            process.env.VUE_APP_BACKEND_URL +
              '/admin/api/user/' +
              this.$route.params.id +
              '/social/reset',
            { name },
            this.$store.getters.getAxiosConfig,
          )
          .then((response) => {
            if (response) {
              if (!response.data.error) {
                this.processUserData(response.data)
              } else {
                this.error = { error: true, message: response.data.message }
              }
            }
          })
          .catch((error) => {
            console.log(error)
            this.error = {
              error: true,
              message: error.response.data.message ?? 'Ошибка сервера',
            }
          })
        } else {
          return
        }
      })
      .catch((err) => {
        console.log(err)
      })
    },
    changeEmail() {
      this.error = { error: false, message: '' }
      this.axios
        .post(
          process.env.VUE_APP_BACKEND_URL +
            '/admin/api/user/' +
            this.$route.params.id +
            '/email',
          { email: this.newEmail },
          this.$store.getters.getAxiosConfig,
        )
        .then((response) => {
          if (response) {
            if (!response.data.error) {
              this.processUserData(response.data)
              this.$bvModal.hide('modal-email')
            } else {
              this.error = { error: true, message: response.data.message }
            }
          }
        })
        .catch((error) => {
          console.log(error)
          this.error = {
            error: true,
            message: error.response.data.message ?? 'Ошибка сервера',
          }
        })
    },
    showToggleConfirm() {
      this.error = { error: false, message: '' }
      this.$bvModal
        .msgBoxConfirm('Изменить состояние отметки выбранных устройств на противоположное?', {
          title: 'Отметка устройств',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Да',
          cancelTitle: 'Нет',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.toggleTestHashes()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    toggleTestHashes() {
      this.$store.commit('toggleTestHash', this.selected_devices.map(item => item.hash))
      this.selected_devices = []
    },
    onChangeData(data) {
      this.user.data = data
    },
    async onSubmit() {
      this.updateDataLoading = true
      try {
        await this.axios.post(
          process.env.VUE_APP_BACKEND_URL +
            '/admin/api/users/' +
            this.$route.params.id +
            '/data',
          this.user.data,
          this.$store.getters.getAxiosConfig,
        )
        this.updateDataLoading = false
      } catch (err) {
        console.error('🐹', err)
        this.$root.$emit('global-error')
        this.updateDataLoading = false
      }
    },
    block() {
      this.$bvModal.msgBoxConfirm('Заблокировать выбранные устройства пользователя?', {
        title: 'Блокировка',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Заблокировать',
        cancelTitle: 'Отмена',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            this.blockUnblockDevices(true)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    remove() {
      this.$bvModal.msgBoxConfirm('Удалить выбранные устройства пользователя?', {
        title: 'Удаление',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Удалить',
        cancelTitle: 'Отмена',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            this.updateDataLoading = true
            this.axios.patch(process.env.VUE_APP_BACKEND_URL + '/admin/api/user/' + this.$route.params.id + '/devices',
              {devices: this.selected_devices.map(item => item.id), remove: true},
              this.$store.getters.getAxiosConfig)
              .then((response) => {
                if (!response.data.error) {
                  this.getData()
                }
              })
              .catch((error) => {
                console.log(error)
              })
              .then(() => {
                this.updateDataLoading = false
              })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    unlock() {
      this.$bvModal
        .msgBoxConfirm('Активировать выбранные устройства пользователя?', {
          title: 'Разблокировка',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: 'Активировать',
          cancelTitle: 'Отмена',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.blockUnblockDevices(false)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    blockUnblockDevices(block) {
      this.updateDataLoading = true
      this.axios
        .patch(
          process.env.VUE_APP_BACKEND_URL +
            '/admin/api/user/' +
            this.$route.params.id +
            '/devices',
          { devices: this.selected_devices.map(item => item.id), block: block },
          this.$store.getters.getAxiosConfig,
        )
        .then((response) => {
          if (!response.data.error) {
            this.getData()
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .then(() => {
          this.updateDataLoading = false
        })
    },
  },
}
</script>

<style scoped>
  .unlink:hover {
    color: red;
    cursor: pointer;
  }
  .test-device {
    color: #007BFF;
    font-weight: 500;
  }
</style>
