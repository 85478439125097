<template>
  <b-modal
    id="user-data-backups-modal"
    title="История бэкапов"
    size="lg"
    scrollable
    @show="getData"
    @hidden="clear"
  >
    <div v-for="(backup, idx) in backups" :key="idx" class="user-data-backups-modal__element">
      <div class="user-data-backups-modal__created">
        {{ formatDate(backup.created_at) }}
      </div>
      <div>{{ backup.data }}</div>
    </div>
    <div v-if="!loading && !backups.length">
      Не было изменений
    </div>
    <div v-if="loading" class="text-center text-primary my-2">
      <b-spinner class="align-middle" />
      <strong class="ml-3">Загрузка данных...</strong>
    </div>
    <template #modal-footer="{ hide }">
      <b-button variant="outline-secondary" @click="hide()">
        Закрыть
      </b-button>
    </template>
  </b-modal>
</template>

<script>
  import dayjs from 'dayjs'
  export default {
    name: 'UserDataBackupsModal',
    props: ['internalId'],
    data() {
      return {
        loading: false,
        backups: [],
      }
    },
    methods: {
      async getData() {
        this.loading = true
        try {
          const response = await this.axios.get(process.env.VUE_APP_BACKEND_URL + '/admin/api/users/' + this.internalId + '/backups', this.$store.getters.getAxiosConfig)
          this.backups = response.data
          this.loading = false
        }
        catch (err) {
          console.error('🐹', err)
          this.$root.$emit('global-error')
          this.loading = false
        }
      },
      clear() {
        this.backups = []
      },
      formatDate(date) {
        return dayjs(date).format('DD.MM.YY hh:mm:ss')
      },
    },
  }
</script>

<style scoped>
  .user-data-backups-modal__created {
    color: #999;
    font-size: 0.8em;
  }

  .user-data-backups-modal__element {
    margin-bottom: 20px;
  }

  #user-data-backups-modal:last-child {
    margin-bottom: 0;
  }
</style>
