<template>
  <b-modal
    id="promo-form-modal"
    title="Добавление промо-доступа"
    @hidden="reset"
  >
    <b-form @submit.prevent="onSubmit">
      <b-form-group label="Доступ (entitlement)">
        <b-select v-model="form.entitlement" :options="entitlements" />
      </b-form-group>
      <b-form-group label="Длительность">
        <b-select v-model="form.duration" :options="durations" />
      </b-form-group>
    </b-form>
    <template #modal-footer="{cancel}">
      <div>
        <b-button
          :disabled="loading"
          variant="primary"
          class="float-right ml-1"
          @click="onSubmit"
        >
          <b-spinner v-if="loading" small />
          Добавить
        </b-button>
        <b-button
          class="float-right"
          @click="cancel()"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import { PROMO_ENTITLEMENTS, PROMO_DURATIONS } from '@/constants.js'

  export default {
    name: 'PromoFormModal',
    props: ['userId'],
    data() {
      return {
        loading: false,
        form: {},
        entitlements: [
          ...PROMO_ENTITLEMENTS,
        ],
        durations: [
          ...PROMO_DURATIONS,
        ],
      }
    },
    created() {
      this.reset()
    },
    methods: {
      async onSubmit() {
        this.loading = true
        try {
          await this.axios.post(process.env.VUE_APP_BACKEND_URL + '/admin/api/users/' + this.userId + '/entitlements', this.form, this.$store.getters.getAxiosConfig)
          this.loading = false
          this.$emit('granted')
          this.hide()
        }
        catch (err) {
          this.loading = false
          console.error(err)
          this.$root.$emit('global-error')
        }
      },
      hide() {
        this.$bvModal.hide('promo-form-modal')
      },
      reset() {
        this.form = {
          entitlement: 'premium',
          duration: 'daily',
        }
      },
    },
  }
</script>

<style scoped>

</style>
